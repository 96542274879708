import { FC, RefObject, useMemo } from 'react';
import { Box, Stack, Theme } from '@mui/material';
import { createUseStyles } from 'react-jss';
import FlatButton from '@/components/flat-button';
import MessageTray from './message-tray';
import Icon from '@/components/icon';
import Text from '@/components/text';
import Select from '@/components/form/select';
import { useQuery } from 'react-query';
import { QueryKey } from '@/lib/query-client';
import useQueryHelper from '@/hooks/use-query-helper';
import Loader from '@/components/loader';
import { sortBy } from 'lodash';
import useThread from '@/hooks/use-thread.hook';
import Tooltip from '@/components/tooltip';
import useMessageTray from '@/hooks/use-message-tray.hook';
import useApp from '@/hooks/use-app.hook';
import { getDefaultOrgAgent, ORG_DEFAULT_AGENT_ID } from '@/lib/services/shortcut.service';
import useUserSettings from '@/hooks/use-user-settings.hook';
import { getPrivateModelConfig } from '@/lib/services/org.service';

interface Props {
  containerRef: RefObject<HTMLDivElement>;
}

const useStyles = createUseStyles((theme: Theme) => ({
  threadSettingsTray: {
    borderTop: `1px solid ${theme.palette.grey[200]}`,
    borderBottom: `1px solid ${theme.palette.grey[200]}`,
    height: '100%',
    width: '100%',
    boxSizing: 'border-box',
  },
  header: {
    padding: '10px 40px',
    marginBottom: 10,
  },
  content: {
    padding: '0px 40px',
    boxSizing: 'border-box',
  },
  headerIcon: {
    marginLeft: -3,
  },
  footer: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    height: 50,
    gap: 8,
    padding: '0px 40px',
    backgroundColor: theme.palette.grey[100],
  },
}));

interface Props {
  containerRef: RefObject<HTMLDivElement>;
}

const ThreadSettingsTray: FC<Props> = ({ containerRef }) => {
  const styles = useStyles();

  const {
    thread,
    setPrivateModelId: setThreadPrivateModelId,
    setAgentId: setThreadAgentId,
  } = useThread();

  const { isOpen, closeAll, toggle } = useMessageTray();
  const open = isOpen('controls');

  const { agents, isLoading: userSettingsLoading } = useUserSettings();
  const { branding } = useApp();

  const query = useQuery([QueryKey.PrivateModelsList], async () => getPrivateModelConfig(), {
    enabled: open,
  });
  const { showLoader: privateModelsLoading } = useQueryHelper(query);
  const { data: pmConfig } = query;

  // append the default org portal branding as the default agent to the list of available agents
  const agentOpts = useMemo(() => {
    return [getDefaultOrgAgent(branding), ...agents].map(({ id, portal }) => {
      const {
        badge: { title },
      } = portal;
      return {
        value: id,
        label: title,
      };
    });
  }, [branding, agents]);

  const handleCloseTray = () => {
    closeAll();
  };

  const handleToggleTray = () => {
    toggle('controls');
  };

  const handleChangePrivateModel = (val: string) => {
    setThreadPrivateModelId(val);
  };

  const handleChangeAgent = (val: string) => {
    setThreadAgentId(val === ORG_DEFAULT_AGENT_ID ? '' : val);
  };

  const privateModelOpts = useMemo(() => {
    const opts = sortBy(pmConfig?.availablePrivateModels || [], 'name').map(({ id, name }) => ({
      value: id,
      label: name,
    }));
    return opts;
  }, [pmConfig]);

  const { privateModelId, agentId = ORG_DEFAULT_AGENT_ID } = thread;

  const safeAgentId = agentId || ORG_DEFAULT_AGENT_ID;
  const safePrivateModelId = privateModelId || pmConfig?.defaultPrivateModelId || '';

  const hasDefaultAgent = !thread.agentId;
  const canChangePrivateModel = hasDefaultAgent;
  const hasAgents = agentOpts.length > 1;
  const isLoading = privateModelsLoading || userSettingsLoading;

  return (
    <Box>
      <MessageTray containerRef={containerRef} height={350} trayId="controls">
        <Box className={styles.threadSettingsTray}>
          {isLoading && (
            <Box width="100%" pt={2}>
              <Loader />
            </Box>
          )}
          {!isLoading && (
            <Stack
              direction="column"
              alignItems="flex-start"
              justifyContent="flex-start"
              width="100%"
              height="100%"
            >
              <Stack
                direction="row"
                alignItems="center"
                gap={0.5}
                justifyContent="flex-start"
                width="100%"
                className={styles.header}
              >
                <Icon name="tune" className={styles.headerIcon} />
                <Text bold>Controls</Text>
              </Stack>

              <Stack flexGrow={1} gap={2} className={styles.content} width="400px">
                <Box width="100%">
                  {hasAgents && (
                    <Select
                      name="agentId"
                      label="Assistant"
                      value={safeAgentId}
                      options={agentOpts}
                      onChange={handleChangeAgent}
                      fullWidth={false}
                      size="small"
                    />
                  )}
                </Box>

                <Box width="100%">
                  <Tooltip
                    disabled={canChangePrivateModel}
                    title="You cannot change the private model for a task when a non-default assistant is selected. In that case, the model is determined by the assistant."
                  >
                    <Select
                      name="privateModelId"
                      label="Private Model"
                      value={safePrivateModelId}
                      options={privateModelOpts}
                      onChange={handleChangePrivateModel}
                      fullWidth={false}
                      size="small"
                      disabled={!canChangePrivateModel}
                    />
                  </Tooltip>
                </Box>
              </Stack>

              <Stack
                className={styles.footer}
                flexDirection="row"
                justifyContent="flex-start"
                alignItems="center"
                height={50}
                gap={1}
                width="100%"
              >
                <Stack
                  flexGrow={1}
                  direction="row"
                  gap={2}
                  alignItems="center"
                  justifyContent="flex-start"
                >
                  <FlatButton icon="close" label="Close" size="small" onClick={handleCloseTray} />
                </Stack>
              </Stack>
            </Stack>
          )}
        </Box>
      </MessageTray>

      <Stack direction="row" alignItems="center" justifyContent="flex-start">
        <FlatButton icon="tune" label="Controls" onClick={handleToggleTray} size="small" />
      </Stack>
    </Box>
  );
};

export default ThreadSettingsTray;
