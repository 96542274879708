import { ReactNode } from 'react';
import { Dataset, JsonObject, getUUID } from '../helpers';
import { findAll, findAllAndCount, getOidParam } from './atlas-data-api.service';
import { ApiResponse } from './index';
import { AuthProvider } from './auth.service';
import axios, { AxiosResponse } from 'axios';
import Environment from '../environment';
import { getDateObj, getTimestamp } from '../date-helpers';
import {
  Citation,
  UserEventMessage,
  UserEventModel,
  UserEventRole,
} from '../models/user-event.model';
import { ShortcutMessage } from '../models/shortcut.model';
import { flatten, uniq } from 'lodash';

export type ThreadRole = 'user' | 'system';

export type ThreadFile = {
  type: 'file';
  name: string;
  ext: string;
  summary: string;
};

export type ThreadDocument = {
  type: 'document';
  name: string;
  url: string;
};

export type ThreadContextSource = ThreadFile | ThreadDocument;

export type ThreadContext = Map<number, ThreadContextSource>;

export type Thread = {
  id: string;
  messages: ThreadMessage[];
  context: ThreadContext;
  datasourceIds: string[];
  privateModelId: string;
  agentId?: string;
  locked?: boolean;
};

export type ThreadMessage = {
  id: string | undefined;
  threadId: string;
  shortcut?: ShortcutMessage;
  context?: ThreadContextSource;
  datasourceIds?: string[];
  privateModelId?: string;
  agentId?: string;
  role: ThreadRole;
  content: string;
  component?: ReactNode;
  createdAt: Date | null;
  onDeck: boolean;
  citations?: Citation[];
};

const DEFAULT_PAGE_SIZE = 100;

const COLLECTION = 'requests';

const axiosClient = axios.create({
  baseURL: Environment.SP_EDGE_API_URL,
  timeout: 120 * 1000,
  responseType: 'json',
  withCredentials: false,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
});

axiosClient.interceptors.request.use((config) => {
  if (AuthProvider.token) {
    config.headers['Surepath-Authorization'] = `Bearer ${AuthProvider.token}`;
  }
  return config;
});

const post = async (path: string, data: JsonObject): Promise<ApiResponse> => {
  return axiosClient.post(path, data).then(handleResponse);
};

const handleResponse = (response: AxiosResponse | null): ApiResponse => {
  if (!response?.data) {
    return null;
  }

  if (Array.isArray(response?.data)) {
    return response?.data as JsonObject[];
  }

  return response?.data as JsonObject;
};

export const getThreadById = async (threadId: string): Promise<Thread | null> => {
  const filter = {
    conversationId: threadId,
    ...getThreadFilter(),
  };

  const response = await findAll(COLLECTION, filter, 0, 1, { timestamp: -1 });

  if (response?.length !== 1) {
    return null;
  }

  const userEvent = new UserEventModel(response[0]);

  const threads = makeThreadsFromUserEvents([userEvent]);

  if (threads.length !== 1) {
    console.error('unable to get exactly one thread by id', threadId);
    return null;
  }

  return threads[0];
};

export const getThreads = async (
  page = 0,
  pageSize = DEFAULT_PAGE_SIZE
): Promise<Dataset<Thread>> => {
  const skip = page * pageSize;
  const filter = { ...getThreadFilter() };

  const { documents, total } = await findAllAndCount(
    COLLECTION,
    filter,
    pageSize,
    skip,
    { _id: -1 },
    'conversationId'
  );

  const rows: UserEventModel[] = documents.map((data) => new UserEventModel(data));

  return {
    page,
    pageSize,
    rows: makeThreadsFromUserEvents(rows),
    total,
  };
};

export const sendUserMessage = async (message: ThreadMessage): Promise<ThreadMessage[] | null> => {
  const { content, shortcut, threadId, datasourceIds, privateModelId, agentId } = message;

  try {
    const response = (await post('/message', {
      messages: [{ role: 'user', content, shortcut, datasourceIds }],
      conversationId: threadId,
      assistantId: agentId,
      privateModelId,
    })) as {
      conversationId: string;
      messages: { role: string; content: string }[];
    };

    if (!Array.isArray(response?.messages)) {
      return null;
    }

    const createdAt = new Date();

    return response.messages.map(({ content, role }) => {
      const systemMessage: UserEventMessage = {
        requestId: '',
        role: role as UserEventRole,
        content,
        timestamp: getTimestamp(createdAt) || 0,
        violations: {
          sensitiveData: false,
          intent: false,
          access: false,
        },
        sensitiveDataDetections: [],
      };

      return makeThreadMessage(threadId, systemMessage);
    });
  } catch (err) {
    return null;
  }
};

const getThreadFilter = (): JsonObject => {
  return {
    orgId: getOidParam(AuthProvider.orgId),
    userId: getOidParam(AuthProvider.spUserId),
    serviceName: 'SurePath AI', // @todo more robust way to filter out "portal" messages
  };
};

/*
 * Create a message that has not yet been sent server-side and turned into a user event. The message can be consumed
 * as expected by the UI, and should be updated to reflect correct id and other information, once the server responds
 */
export const makeDeckMessage = (
  threadId: string,
  role: ThreadRole,
  content: string,
  shortcut?: ShortcutMessage
): ThreadMessage => {
  const createdAt = new Date();
  return {
    id: makeThreadMessageId(threadId, {
      role,
      timestamp: getTimestamp(createdAt),
    } as UserEventMessage),
    threadId,
    shortcut,
    role,
    content,
    createdAt: new Date(),
    onDeck: true,
  };
};

export const makeDeckFileMessage = (threadId: string, filename: string, summary = '') => {
  const fileMessage = makeDeckMessage(threadId, 'user', '', undefined);
  fileMessage.context = {
    type: 'file',
    name: filename,
    ext: String(filename).split('.').pop() || '',
    summary,
  };
  return fileMessage;
};

const makeThreadsFromUserEvents = (userEvents: UserEventModel[]): Thread[] => {
  const threads: Thread[] = [];

  userEvents.forEach(({ conversationId, messages }) => {
    const { conversation } = messages;

    const threadMessages = conversation
      .filter((message) => {
        // context datasource entries are not inline, displayed parts of the thread, whereas user-uploaded files are shown inline
        const context = makeThreadContextSource(message);
        return context?.type !== 'document';
      })
      .map((message) => makeThreadMessage(conversationId, message));

    const thread: Thread = {
      id: conversationId,
      messages: threadMessages,
      context: makeContextFromConversation(conversation),
      locked: getLockFromConversation(conversation),
      datasourceIds: getDatasourceIdsFromConversation(conversation),
      privateModelId: getPrivateModelIdFromConversation(conversation),
      agentId: getAgentIdFromConversation(conversation),
    };

    threads.push(thread);
  });

  sortThreads(threads);

  return threads;
};

export const makeThreadMessage = (
  conversationId: string,
  eventMessage: UserEventMessage
): ThreadMessage => {
  const {
    role: userEventRole,
    content,
    timestamp,
    shortcut,
    citations,
    datasourceIds,
    privateModelId,
    assistantId,
  } = eventMessage;

  const role = makeThreadRole(userEventRole);

  const message: ThreadMessage = {
    id: makeThreadMessageId(conversationId, eventMessage),
    threadId: conversationId,
    shortcut: shortcut?.id ? shortcut : undefined,
    role,
    content,
    createdAt: getDateObj(timestamp),
    onDeck: false,
    citations,
    datasourceIds,
    privateModelId: privateModelId || '',
    agentId: assistantId || '',
  };

  if (isContextMessage(eventMessage)) {
    message.content = '';
    message.context = makeThreadContextSource(eventMessage) || undefined;
  }

  return message;
};

const makeThreadContextSource = (eventMessage: UserEventMessage): ThreadContextSource | null => {
  const { origin, type, name, source, summary = '' } = eventMessage.contextDetails || {};

  if (origin === 'user' && type === 'file' && name) {
    return {
      type: 'file',
      name,
      ext: String(name).split('.').pop() || '',
      summary,
    };
  }

  if (origin === 'enterprise' && type === 'document') {
    return {
      type: 'document',
      name: name || '',
      url: source || '',
    };
  }

  return null;
};

const makeThreadMessageId = (conversationId: string, eventMessage: UserEventMessage) => {
  const { role } = eventMessage;
  return `${conversationId}|${role}|${getUUID()}`;
};

// sort by date of last user message
export const sortThreads = (threads: Thread[]) => {
  threads.sort((aThread, bThread) => {
    const aUserMessages = aThread.messages.filter(({ role }) => role === 'user');
    const aLastMessage = aUserMessages[aUserMessages.length - 1];

    const bUserMessages = bThread.messages.filter(({ role }) => role === 'user');
    const bLastMessage = bUserMessages[bUserMessages.length - 1];

    return (
      (getTimestamp(bLastMessage?.createdAt) || 0) - (getTimestamp(aLastMessage?.createdAt) || 0)
    );
  });
};

const makeThreadRole = (eventRole: UserEventRole): ThreadRole => {
  switch (String(eventRole).toLowerCase()) {
    case 'user':
    case 'context':
      return 'user';
    default:
      return 'system';
  }
};

const isContextMessage = (eventMessage: UserEventMessage): boolean => {
  const { role } = eventMessage;

  if (role !== 'context') {
    return false;
  }

  return true;
};

export const makeContextFromConversation = (conversation: UserEventMessage[]): ThreadContext => {
  const context: ThreadContext = new Map();

  conversation.forEach((eventMessage, index) => {
    if (!isContextMessage(eventMessage)) {
      return;
    }

    const contextSource = makeThreadContextSource(eventMessage);

    if (contextSource) {
      context.set(index, contextSource);
    }
  });

  return context;
};

export const getLockFromConversation = (conversation: UserEventMessage[]): boolean => {
  return conversation.some(({ violations }) => !!violations?.intent);
};

const getDatasourceIdsFromConversation = (conversation: UserEventMessage[]): string[] => {
  return uniq(flatten(conversation.map(({ datasourceIds }) => datasourceIds || [])));
};

const getPrivateModelIdFromConversation = (conversation: UserEventMessage[]): string => {
  if (!conversation.length) {
    return '';
  }
  return conversation[conversation.length - 1].privateModelId || '';
};

const getAgentIdFromConversation = (conversation: UserEventMessage[]): string => {
  if (!conversation.length) {
    return '';
  }
  return conversation[conversation.length - 1].assistantId || '';
};
